// Here you can add other styles

@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
@import '~react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

// @import '~react-select/dist/react-select.css';

@import '~ladda/dist/ladda-themeless.min.css';

@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/material.css';

.CodeMirror {
  height: auto;
}

.gutter-vertical_bottom_15 {
  margin-bottom: 15px;
}

@import 'Shared/variables';
@import 'Shared/Mixins/index';
@import 'Shared/Input/index';

@import '../app/shared/inputs/dropzone/StyledDropzone';
@import '../app/shared/inputs/daypicker/DayPickerInput';
