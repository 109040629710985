.account-dropdown {
  &__toggle {
    padding: 15px;

    > span {
      display: inline-block;
      margin-right: 5px;
    }
  }
}
