@import '~react-day-picker/lib/style.css';

.DayPickerInput {
  input {
    padding: .5em 1em;
    border: 1px solid #ddd;
    font-size: 1em;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
    text-align: center;
    font-weight: 400;
  }
}
