.radio-input {
  > label {
    position: relative;
    border-radius: 4px;
    display: inline-block;

    > span {
      width: 100%;
      font-size: 16px;
      color: $border-color;
      display: inline-block;
      user-select: none;
    }
  }
}

