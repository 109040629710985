/*
$LetterIcon-img: '/src/Assets/img/LetterIcon.svg';
$wolf-img: '/src/Assets/img/wolf.svg';
*/

.personal {
  &-dnd {
    background: url("/src/Assets/img/LetterIcon.svg") center 22px / auto no-repeat;
    padding-top: 1px;

    &__content {
      max-width: 400px;
      margin: 75.5px auto 20.5px;
      text-align: center;
    }

    &__text {
      margin-bottom: 10px;
      font-size: 20px;
    }

    &__label {
      cursor: pointer;
    }

    &__caption {
      font-weight: bold;
    }
  }

  &-dnd_image {
    padding-top: 10.5px;

    &__content {
      text-align: center;
    }

    &__img {
      width: calc(100% - 0px);
    }

    &__caption {
      font-weight: bold;
      margin: 15.5px 0;
    }
  }
}

.pets {
  .content-grid .content-left {
    flex-basis: 219px;
  }

  &-dnd {
    background: url("/src/Assets/img/wolf.svg") center 39px / auto no-repeat;
    padding-top: 10px;

    &__content {
      max-width: 250px;
      margin: 166.5px auto 86.5px;
      text-align: center;
    }

    &__text {
      margin-bottom: 10px;
      font-size: 20px;
    }

    &__label {
      cursor: pointer;
    }

    &__caption {
      font-weight: bold;
    }
  }

  &-dnd_image {
    padding-top: 10.5px;

    &__content {
      text-align: center;
    }

    &__img {
      width: calc(100% - 0px);
    }

    &__caption {
      font-weight: bold;
      margin: 15.5px 0;
    }
  }

  @include media-breakpoint-down(lg) {
    &__dnd {
      margin-bottom: 31px;
    }

    &-dnd {
      background-position: center 21px;

      &__text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      &__content {
        margin: 126px auto 20px;
      }
    }
  }

  .content-grid .content-right {
    flex-basis: 380px;
  }

  &__form {
    margin-top: 20px;
  }

  &__input-group {
    margin-bottom: 30px;
    justify-content: space-between;

    .input-group__item {
      &:first-of-type {
        flex-grow: 1;
      }
    }
  }

  &__color-input {
    width: 220px;
    margin-bottom: 20px;
  }

  &__is-neutered-caption {
    color: $main-text;
    font-size: 16px;
    margin-bottom: 10px;
  }

  @include media-breakpoint-down(lg) {
    .content-left,
    .content-right {
      flex-basis: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    &__color-input {
      width: 153px;
      margin-bottom: 30px;
    }

    &__input-group {
      margin-bottom: 30px;
    }
  }
}
