.check-input {
  input[type="radio"],
  input [type="checkbox"] {
    &:checked {
      + span {
        border: none;
        border-radius: 4px;
      }
    }
  }

  span {
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    user-select: none;
  }
}
