.check-input {
  &_filled {
    input[type="radio"],
    input[type="checkbox"] {
      &:checked + span {
        background-color: $active-color;
        color: $white;
        border-radius: 4px;
      }
    }
  }
}
