// Colors

$active-color: #175DE4;
$main-text: #01163D;
$secondary-text: #A3B3D1;
$border-color: #D1D8E6;
$fill-buttons: #F1F5FD;
$negative-color: #FF4848;
$grey-background: #F2F2F2;
$white: #FFF;

//Shadows
$active-button-shadow:
        0 5px 10px 0 rgba(23, 93, 228, 0.3),
        0 2px 5px 0 rgba(18, 64, 153, 0.2);
$popup-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
