.radio-buttons {
  label {
    text-align: center;
    width: 120px;

    > span {
      line-height: 50px;
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}
