@import '../variables';

@mixin multirow {
  flex-wrap: wrap;
}

@mixin btn-transparent {
  color: $active-color;
  background: transparent;
  font-weight: 700;
}

@mixin block-control {
  display: block;
  width: 100%;
}

// Use only inside pseudoelements
@mixin checkmark {
  content: '';
  position: absolute;
  width: 12px;
  height: 7px;
  border: solid $white;
  border-width: 0 0 2px 2px;
  transform: rotate(-50deg);
}

@mixin radio-input-separator {
  content: '';
  position: absolute;
  top: calc(50% - 14px);
  display: block;
  width: 1px;
  height: 28px;
}

@mixin media-breakpoint-down($size, $include-landscape: true) {
  // Small monitors
  @if $size == xl {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $size == lg {
    // Tablets
    @media (max-width: 991px) {
      @content;
    }
  } @else if $size == md {
    @if $include-landscape {
      @media (max-width: 767px), (max-width: 1023px) and (orientation: landscape) {
        @content;
      }
    } @else {
      @media (max-width: 767px) {
        @content;
      }
    }
  } @else if $size == sm {
    @if $include-landscape {
      // Smartphones, including landscape orientation
      @media (max-width: 575px), (max-width: 1023px) and (orientation: landscape) {
        @content;
      }
    } @else {
      // Smartphones with portrait orientation
      @media (max-width: 575px) and (orientation: portrait) {
        @content;
      }
    }
  }
}

@mixin media-breakpoint-up($size, $include-landscape: true) {
  // Small monitors
  @if $size == xl {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == lg {
    // Tablets
    @media (min-width: 992px) {
      @content;
    }
  } @else if $size == md {
    @if $include-landscape {
      @media (min-width: 768px) and (orientation: portrait), (min-width: 1024px) and (orientation: landscape) {
        @content;
      }
    } @else {
      @media (min-width: 768px) {
        @content;
      }
    }
  } @else if $size == sm {
    @media (min-width: 576px) {
      @content;
    }
  }
}

@mixin transition($args...) {
  transition: $args;
}

@mixin title-main {
  font-family: 'Lato', sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 45px;

  @include media-breakpoint-down(sm) {
    font-size: 25px;
    line-height: 30px;
  }
}
