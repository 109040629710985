.checkbox {
  &_checkmark {
    input[type="checkbox"] {
      &:focus + span {
        &:before {
          outline: rgb(59, 153, 252) auto 5px;
        }
      }

      &:checked + span {
        &:before {
          background-color: $active-color;
          border-color: transparent;
        }

        &:after {
          @include checkmark;
          top: 5px;
          left: 4px;
        }
      }
    }

    > span {
      display: flex;
      align-items: center;
      position: relative;

      &:before {
        border: 1px solid $border-color;
        border-radius: 2px;

        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
