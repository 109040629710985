.radio-input {
  &_joined {
    display: inline-block;
    height: 50px;

    > label {
      line-height: 48px;

      > span {
        padding: 0 15px;
      }

      &:not(:last-child) > span:after {
        @include radio-input-separator;
        right: 0;
        background-color: $secondary-text;
      }
    }

    input[type="radio"]:checked + span {
      &:before, &:after {
        @include radio-input-separator;
      }

      &:before {
        left: -1px;
        background-color: $white;
        z-index: 1000;
      }

      &:after {
        right: 0.5px;
        background-color: $active-color;
      }
    }
  }
}
