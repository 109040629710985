@import '_label/_label';
@import '_filled/filled';
@import '_radio/radio';
@import '_checkbox/checkbox';

.check-input {
  display: inline-block;
  user-select: none;

  input[type="checkbox"],
  input[type="radio"] {
    // Don't use display: none to retain accessibility
    position: absolute;
    left: -9999px;
  }
}
